import { Tile } from '../types';

type RewardPointsValidation = (
    value: string,
    allValues: Tile,
) => string | undefined;

const rewardPointsValidation: RewardPointsValidation = (value, allValues) => {
    const {
        reward_points_minimum_enabled,
        reward_points_maximum_enabled,
        reward_points_maximum,
        reward_points_minimum,
    } = allValues;

    if (
        reward_points_maximum
        && reward_points_minimum
        && reward_points_maximum_enabled
        && reward_points_minimum_enabled
        && reward_points_maximum < reward_points_minimum
    ) {
        return 'Minimum should be lower than Maximum';
    }

    // React Admin doc specifies that you should return undefined when you have no error
    // See here : https://marmelab.com/react-admin/CreateEdit.html#per-input-validation-custom-function-validator
    return undefined;
};

export default rewardPointsValidation;
