import * as MUI from '@mui/material';
import * as RA from 'react-admin';

const StoryScreenEditModal = ({ children, open, handleClose }) => {
    const record = RA.useRecordContext();

    return (
        <MUI.Modal
            open={open === record.id}
            onClose={handleClose}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
            <>
                {children}
            </>
        </MUI.Modal>
    );
};

export default StoryScreenEditModal;
