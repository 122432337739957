import { useState } from 'react';

type useMultipleModalFunctions = {
    handleOpenId: (id: number | string) => () => void;
    handleClose: () => void;
    open: boolean | number | string;
};

type UseMultipleModal = () => useMultipleModalFunctions;

const useMultipleModal: UseMultipleModal = () => {
    const [open, setOpen] = useState<boolean | number | string>(false);
    const handleOpenId = (id: number | string) => () => setOpen(id);
    const handleClose = () => setOpen(false);

    return { handleOpenId, handleClose, open };
};

export default useMultipleModal;
