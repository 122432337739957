import { TileValidation } from '../types';

type CobrandPartnerValidation = (
    value: string,
    allValues: TileValidation,
) => string | undefined;

export const validateFlyingPartner: CobrandPartnerValidation = (
    value,
    allValues,
) => {
    const { cobrand_flying_partner } = allValues;
    if (cobrand_flying_partner && cobrand_flying_partner.length === 0) {
        return 'Choose at least one partner or disable filter.';
    }

    return undefined;
};

export const validateAllSignature: CobrandPartnerValidation = (
    value,
    allValues,
) => {
    const { cobrand_all_signature } = allValues;
    if (cobrand_all_signature && cobrand_all_signature.length === 0) {
        return 'Choose at least one signature card or disable filter.';
    }

    return undefined;
};

export const validateAllPlusCard: CobrandPartnerValidation = (
    value,
    allValues,
) => {
    const { all_plus_card } = allValues;
    if (all_plus_card && all_plus_card.length === 0) {
        return 'Choose at least one All Plus card or disable filter.';
    }
    return undefined;
};

export const validateWooriCard: CobrandPartnerValidation = (
    value,
    allValues,
) => {
    const { woori_card } = allValues;
    if (woori_card && woori_card.length === 0) {
        return 'Choose at least one Woori Card or disable filter.';
    }
    return undefined;
};
