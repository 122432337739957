import * as RA from 'react-admin';
import * as MUI from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLogin } from 'ra-core';

const Login = () => {
    const loginParams = { _withAzure: true };
    const login = useLogin();
    const handleLogin = (e: any) => {
        e.preventDefault();
        login(loginParams).catch((err: any) => {
            // eslint-disable-next-line no-console
            console.warn('[Login] login error: ', err);
        });
    };

    return (
        <RA.Login>
            <MUI.Box sx={{ padding: 2 }}>
                <MUI.Button
                    color="secondary"
                    variant="contained"
                    onClick={handleLogin}
                    fullWidth
                >
                    login with your Accor account
                </MUI.Button>
            </MUI.Box>
        </RA.Login>
    );
};

export default Login;
