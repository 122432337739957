import * as RA from 'react-admin';

interface Choice {
    id: string;
    name: string;
}

const AutocompleteCountriesInput = ({ source, choices: inputChoices, ...props }: RA.AutocompleteArrayInputProps) => {
    const choices = inputChoices ? inputChoices.map(({ id, name }: Choice) => (
        { id, name: `${id.toUpperCase()} - ${name}` })) : [];

    return <RA.AutocompleteArrayInput
        source={source}
        choices={choices}
        { ...props }
        fullWidth
    />;
};

export default AutocompleteCountriesInput;
