import React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { JsonInput } from 'react-admin-json-view';

export const ConfigsForm = ({ toolbar: Toolbar }: any): React.ReactElement => (
    <MUI.Box paddingY={3} display="flex" flexDirection="column">
        <RA.SimpleForm
            toolbar={Toolbar}
            mode={'onBlur'}
            warnWhenUnsavedChanges
        >
            <MUI.Box width="100%" display="flex" flexDirection="row" alignItems="center">
                <RA.TextInput
                    source="slug"
                    validate={RA.required()}
                    helperText={false}
                    sx={{ width: '100%', marginRight: '1em' }}
                />
                <RA.DateInput
                    source="date_start"
                    helperText={false}
                    fullWidth
                    sx={{ width: '100%', marginRight: '1em' }}
                />
                <RA.DateInput
                    source="date_end"
                    helperText={false}
                    fullWidth
                />
            </MUI.Box>
            <JsonInput
                source="keys"
                validate={RA.required()}
                jsonString={false} // Set to true if the value is a string, default: false
                reactJsonOptions={{
                    // Props passed to react-json-view
                    name: null,
                    collapsed: true,
                    enableClipboard: false,
                    displayDataTypes: false,
                }}
            />
        </RA.SimpleForm>
    </MUI.Box>

);
