import * as React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Visibility, VisibilityOff } from '@mui/icons-material';

import CustomEditTitle from '../CustomTitle/CustomEditTitle';

import { TilesForm } from './TilesForm';
import { TilesPreview } from './TilesPreview';

import { BulkActionButtons } from '../CustomButtons/BulkActionButtons';
import { tilesFilters } from '../Filters/TilesListFilters';

const ListPagination = (props: RA.PaginationProps) => <RA.Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const isValidLocale = (locale: string, record: Record<string, any>): boolean => {
    if (record.type === 'standard') {
        return Boolean(record.content?.title?.[locale] && record.content?.subtitle?.[locale]);
    }

    return Boolean(record.content?.title?.[locale]);
};

const PostListActions = () => (
    <RA.TopToolbar>
        <RA.SelectColumnsButton />
        <RA.FilterButton />
        <RA.CreateButton />
        <RA.ExportButton />
    </RA.TopToolbar>
);

export const List = (): React.ReactElement => {
    const [selectedRow, setSelectedRow] = React.useState<Record<string, any> | null>(null);
    const [selectedLocale, setSelectedLocale] = React.useState<string | null>(null);

    const onRowClick = (id: RA.Identifier, resource: String, record: Record<string, any>) => {
        const locales = Object.keys(record.content.title || {});
        const firstLocale = locales.find((locale: string) => isValidLocale(locale, record));

        if (firstLocale) {
            setSelectedRow(record);
            setSelectedLocale(firstLocale);
        } else {
            setSelectedRow(null);
            setSelectedLocale(null);
        }

        // return 'expand' to not redirect to other pages when row is clicked
        // c.f. https://marmelab.com/react-admin/List.html#rowclick
        return 'expand';
    };

    return (
        <MUI.Box display="flex" flexDirection="row">
            <MUI.Box flex={1}>
                <RA.List
                    perPage={100}
                    pagination={<ListPagination />}
                    filters={tilesFilters()}
                    hasShow={false}
                    actions={<PostListActions />}
                >
                    <RA.DatagridConfigurable
                        rowClick={onRowClick}
                        bulkActionButtons={<BulkActionButtons />}
                    >
                        <RA.BooleanField
                            source="published"
                            label="Visible"
                            TrueIcon={Visibility}
                            FalseIcon={VisibilityOff}
                        />
                        <RA.TextField source="tilename" label="Title" />
                        <RA.TextField source="priority" label="Priority" />
                        <RA.TextField source="type" label="Type" />
                        <RA.CloneButton />
                        <RA.EditButton />
                    </RA.DatagridConfigurable>
                </RA.List>
            </MUI.Box>
            <MUI.Box display="flex" justifyContent="center" maxWidth={600} padding="0px 50px 0">
                <TilesPreview
                    data={{
                        picture: selectedRow?.content?.picture,
                        title: selectedRow?.content?.title,
                        subtitle: selectedRow?.content?.subtitle,
                        incentive: selectedRow?.content?.incentive,
                        picto: selectedRow?.content?.picto,
                    }}
                    selectedLocale={selectedLocale}
                />
            </MUI.Box>
        </MUI.Box>
    );
};

const CreateToolBar = (props: RA.ToolbarProps): React.ReactElement => (
    <RA.Toolbar {...props}>
        <RA.SaveButton
            type="button"
            alwaysEnable
        />
    </RA.Toolbar>
);

export const Create = (): React.ReactElement => (
    <RA.Create redirect={'list'}>
        <TilesForm toolbar={<CreateToolBar />} />
    </RA.Create>
);

const EditToolBar = (): React.ReactElement => (
    <RA.Toolbar sx={{ gap: 4 }}>
        <RA.SaveButton
            type="button"
            alwaysEnable
        />
        <RA.DeleteWithConfirmButton />
    </RA.Toolbar>
);

export const Edit = (): React.ReactElement => (
    <RA.Edit redirect={'list'} mutationMode="pessimistic" title={<CustomEditTitle name={'tile'} field={'tilename'} />}>
        <TilesForm toolbar={<EditToolBar />} />
    </RA.Edit>
);
