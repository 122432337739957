import { ExpirationTime, UserType } from '../../../../types';
import useMultipleLinkedInputs from '../../../LinkedInputs/useMultipleLinkedInputs';
import useLinkedInput from '../../../LinkedInputs/useLinkedInput';

export const isUserProspectFromFormData = (formData: Record<string, any>): boolean => formData.user_type === UserType.prospect;
const setIfUserProspect = (value: any) => (userType: UserType): any => (UserType.prospect === userType ? value : undefined);

const onChangeUserType = () => useMultipleLinkedInputs([
    {
        targetName: 'tiering',
        targetValueFn: (userType: UserType) => ([UserType.accorPlus, UserType.memberAllNoAccorPlus, UserType.everyMemberAll].includes(userType) ? undefined : null),
    },
    {
        targetName: 'user_card_expiration',
        targetValueFn: (userType: UserType) => ([UserType.accorPlus, UserType.memberAllNoAccorPlus, UserType.everyMemberAll].includes(userType) ? undefined : ExpirationTime.anytime),
    },
    {
        targetName: 'filter_by_cobrand_visa',
        targetValueFn: setIfUserProspect(false),
    },
    {
        targetName: 'filter_by_cobrand_flying_partner',
        targetValueFn: setIfUserProspect(false),
    },
    {
        targetName: 'filter_by_all_plus_card',
        targetValueFn: setIfUserProspect([]),
    },
    {
        targetName: 'reward_points_minimum_enabled',
        targetValueFn: setIfUserProspect(false),
    },
    {
        targetName: 'reward_points_maximum_enabled',
        targetValueFn: setIfUserProspect(false),
    },
    {
        targetName: 'cobrand_visa',
        targetValueFn: setIfUserProspect([]),
    },
    {
        targetName: 'cobrand_flying_partner',
        targetValueFn: setIfUserProspect([]),
    },
    {
        targetName: 'cobrand_all_signature',
        targetValueFn: setIfUserProspect([]),
    },
    {
        targetName: 'all_plus_card',
        targetValueFn: setIfUserProspect([]),
    },
    {
        targetName: 'woori_card',
        targetValueFn: setIfUserProspect([]),
    },
    {
        targetName: 'reward_points_minimum',
        targetValueFn: setIfUserProspect(0),
    },
    {
        targetName: 'reward_points_maximum',
        targetValueFn: setIfUserProspect(0),
    },
]);

const onChangeFilterByCobrandVisa = () => useLinkedInput({
    targetName: 'cobrand_visa',
    targetValue: [],
});

const onChangeFilterByCobrandFlyingPartner = () => useLinkedInput({
    targetName: 'cobrand_flying_partner',
    targetValue: [],
});

const onChangeFilterByCobrandAllSignature = () => useLinkedInput({
    targetName: 'cobrand_all_signature',
    targetValue: [],
});
const onChangeFilterByAllPlusCard = () => useLinkedInput({
    targetName: 'all_plus_card',
    targetValue: [],
});
const onChangeFilterByWooriCard = () => useLinkedInput({
    targetName: 'woori_card',
    targetValue: [],
});

const onChangeRewardPointsMinimumEnabled = () => useLinkedInput({
    targetName: 'reward_points_minimum',
    targetValue: 0,
});

const onChangeRewardPointsMaximumEnabled = () => useLinkedInput({
    targetName: 'reward_points_maximum',
    targetValue: 0,
});

const getOnChangeFunctions = () => ({
    userType: onChangeUserType(),
    filterByCobrandAllSignature: onChangeFilterByCobrandAllSignature(),
    filterByCobrandFlyingPartner: onChangeFilterByCobrandFlyingPartner(),
    filterByCobrandVisa: onChangeFilterByCobrandVisa(),
    filterByAllPlusCard: onChangeFilterByAllPlusCard(),
    filterByWooriCard: onChangeFilterByWooriCard(),
    rewardPointsMinimumEnabled: onChangeRewardPointsMinimumEnabled(),
    rewardPointsMaximumEnabled: onChangeRewardPointsMaximumEnabled(),
});

export default getOnChangeFunctions;
