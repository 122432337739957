export interface Config {
    VITE_API_URL: string;
    VITE_ADMIN_BASE_URL: string;
    VITE_COGNITO_IDENTITY_POOL_ID: string;
    VITE_COGNITO_USER_POOL_ID: string;
    VITE_COGNITO_WEB_CLIENT_ID: string;
    VITE_COGNITO_DOMAINE: string;
}

let config: Config;

const getConfig = (): Config => {
    const {
        VITE_API_URL,
        VITE_ADMIN_BASE_URL,
        VITE_COGNITO_IDENTITY_POOL_ID,
        VITE_COGNITO_USER_POOL_ID,
        VITE_COGNITO_WEB_CLIENT_ID,
        VITE_COGNITO_DOMAINE,
    } = config || {};

    return {
        ...(config || {}),
        VITE_API_URL:
            VITE_API_URL
            || (import.meta.env.VITE_API_URL as string),
        VITE_ADMIN_BASE_URL:
            VITE_ADMIN_BASE_URL
            || (import.meta.env.VITE_ADMIN_BASE_URL as string),
        VITE_COGNITO_IDENTITY_POOL_ID:
            VITE_COGNITO_IDENTITY_POOL_ID
            || (import.meta.env.VITE_COGNITO_IDENTITY_POOL_ID as string),
        VITE_COGNITO_USER_POOL_ID:
            VITE_COGNITO_USER_POOL_ID
            || (import.meta.env.VITE_COGNITO_USER_POOL_ID as string),
        VITE_COGNITO_WEB_CLIENT_ID:
            VITE_COGNITO_WEB_CLIENT_ID
            || (import.meta.env.VITE_COGNITO_WEB_CLIENT_ID as string),
        VITE_COGNITO_DOMAINE:
            VITE_COGNITO_DOMAINE
            || (import.meta.env.VITE_COGNITO_DOMAINE as string),
    };
};

export const loadConfig = async (): Promise<Config> => {
    const response = await fetch('/conf.json');
    if (response.status !== 200) throw new Error('Remote configuration load failed');
    config = await response.json();
    return getConfig();
};

export default getConfig;
