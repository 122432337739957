import * as RA from 'react-admin';
import { useFormContext } from 'react-hook-form';

interface Choice {
    id: string;
    name: string;
}

const CheckBoxInputWithSelectAll = ({ source, choices: inputChoices, ...props }: RA.CheckboxGroupInputProps) => {
    const { getValues } = useFormContext();
    const { setValue } = useFormContext();

    const sourceValues = getValues(`${source}`);

    const choices = [
        { id: 'select-all', name: 'Select all' },
        ...inputChoices || [],
    ];

    const onChange = (event: object) => {
        if (!Array.isArray(event)) {
            // eslint-disable-next-line no-console
            console.error('unknown event type');
            return;
        }

        if (event.includes('select-all') && !sourceValues.includes('select-all')) {
            setValue(`${source}`, choices.map((choice: Choice) => choice.id));
        } else if (!event.includes('select-all') && sourceValues.includes('select-all')) {
            setValue(`${source}`, []);
        }
    };
    return <RA.CheckboxGroupInput
        source={source}
        choices={choices}
        onChange={onChange}
        {...props}
    />;
};

export default CheckBoxInputWithSelectAll;
