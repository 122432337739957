import React from 'react';
import * as MUI from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { useRecordContext } from 'react-admin';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Edit, Settings } from '@mui/icons-material';
import SettingsPanel from './Panels/Settings/SettingsPanel';
import StandardContentPanel from './Panels/StandardContentPanel';
import ServiceContentPanel from './Panels/ServiceContentPanel';

import TileTabs from './TileTabs';

import { resetServiceTile, resetStandardTile } from '../../formReset/tileFormReset';

export const TileFormTabs = () => {
    const formContext = useFormContext();
    const record = useRecordContext();
    const type = formContext.getValues('type');
    const [initalType, setInitialType] = React.useState(record ? record.type : '');

    React.useEffect(() => {
        if (record && type !== initalType) {
            if (type === 'standard') formContext.reset(resetStandardTile(record, type));
            if (type === 'service') formContext.reset(resetServiceTile(record, type));
            setInitialType(type);
        } else if (!record && type !== initalType) {
            if (type === 'standard') formContext.reset(resetStandardTile({}, type));
            if (type === 'service') formContext.reset(resetServiceTile({}, type));
            setInitialType(type);
        }
    }, [type]);

    return (
        <MUI.Box width="100%">
            <TileTabs
                icons={[<Edit />, <Settings />]}
                tabs={['Content', 'Settings']}
            >
                {type === 'service' ? <ServiceContentPanel /> : <StandardContentPanel />}
                <SettingsPanel />
            </TileTabs>
        </MUI.Box>
    );
};
