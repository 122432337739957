import * as React from 'react';
import { CustomBulkDelete } from './CustomBulkDelete';
import { CustomBulkCopyIds } from './CustomBulkCopyIds';

export const BulkActionButtons = (props: Record<string, string>) => (
    <React.Fragment>
        {props.resource === 'tiles' && <CustomBulkCopyIds />}
        <CustomBulkDelete resource={props.resource} />
    </React.Fragment>
);
