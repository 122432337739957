import * as React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { useFormContext } from 'react-hook-form';
import { useRecordContext } from 'react-admin';

// eslint-disable-next-line import/no-extraneous-dependencies
import CloseIcon from '@mui/icons-material/Close';

import { StoryScreen } from '../../../types';
import { locales } from '../../../locales';
import { TileLocaleSelector } from '../../FormComponents/TileLocaleSelector';

import * as Types from '../../../types';

const storyScreenTemplates = [
    { id: Types.StoryTemplate.fullscreen, name: 'fullscreen' },
    { id: Types.StoryTemplate.animated, name: 'animated' },
    { id: Types.StoryTemplate.bulleted, name: 'bulleted' },
];

const StoryScreenForm = ({
    handleClose, open,
}) => {
    const record = useRecordContext<StoryScreen>();
    const { getValues, setValue } = useFormContext();
    const { selectLocale, selectedLocale } = RA.useTranslatableContext();
    const handleLocaleChange = (locale: string) => selectLocale(locale);

    const storyScreens = getValues('content.story_screens');

    const extractStoryScreenFromSubmit = (formValues: any): Types.TileValidation['content']['story_screens'] => {
        const {
            screen_name, template, title, supertitle, body, body_extended, media, tracking_label,
        } = formValues;
        const id = formValues?.id || null;
        const animation = formValues?.animation || null;
        const items_list = formValues?.items_list || null;

        const newStoryScreen = {
            id,
            screen_name,
            template,
            title,
            supertitle,
            body,
            body_extended,
            media,
            tracking_label,
        };

        if (animation) return { ...newStoryScreen, animation };
        if (items_list) return { ...newStoryScreen, items_list };

        return newStoryScreen;
    };

    const onStoryCreateSubmit = async (formValues: any) => {
        const extractedStoryScreen = extractStoryScreenFromSubmit(formValues);

        const newStoryScreens = [...storyScreens, { ...extractedStoryScreen, id: uuidv4() }];

        setValue('content.story_screens', newStoryScreens);
        handleClose();
    };

    const onStoryEditSubmit = async (formValues: any) => {
        const extractedStoryScreen = extractStoryScreenFromSubmit(formValues);

        const newStoryScreens = storyScreens.map(
            (storyScreen: StoryScreen) => (storyScreen.id === extractedStoryScreen.id ? extractedStoryScreen : storyScreen),
        );

        setValue('content.story_screens', newStoryScreens);
        handleClose();
    };

    const CustomToolbar = (props: RA.ToolbarProps): React.ReactElement => (
        <RA.Toolbar {...props} sx={{ gap: 4 }}>
            <RA.SaveButton alwaysEnable />
            <MUI.Button
                variant="text"
                color="error"
                startIcon={<CloseIcon />}
                onClick={handleClose}
            >
                Cancel
            </MUI.Button>
        </RA.Toolbar>
    );

    return <MUI.Card sx={{ width: 'fitContent', maxHeight: '95%', overflow: 'auto' }}>
        <RA.SimpleForm
            onSubmit={open === 'new' ? onStoryCreateSubmit : onStoryEditSubmit}
            toolbar={<CustomToolbar />}
            defaultValue={open !== 'new' && storyScreens.find((story: StoryScreen) => story.id === record.id)}
        >
            <MUI.Typography variant="h6" mt={3}>Info</MUI.Typography>
            <RA.TextInput source="screen_name" label="screen_name" fullWidth helperText={false} validate={RA.required()} />
            <RA.SelectInput
                source="template"
                defaultValue={Types.StoryTemplate.fullscreen}
                choices={storyScreenTemplates}
                validate={RA.required()}
                emptyValue={false}
                helperText={false}
                fullWidth
            />

            <MUI.Typography variant="h6" mt={3}>Text Localization</MUI.Typography>
            <RA.TranslatableInputs
                locales={locales}
                defaultLocale={selectedLocale}
                groupKey="modal-text-localization"
                selector={
                    <TileLocaleSelector onChange={handleLocaleChange} />
                }
            >
                <RA.TextInput source="title" label="Title" fullWidth helperText={false} />
                <RA.TextInput
                    source="supertitle"
                    label="Supertitle"
                    fullWidth
                />
                <RA.TextInput source="body" label="Body" fullWidth helperText={false} />
                <RA.TextInput source="body_extended" label="Body Extended" fullWidth helperText={false} />
            </RA.TranslatableInputs>

            <MUI.Typography variant="h6" mt={3}>Media</MUI.Typography>
            <MUI.Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <RA.TextInput source="media.image" label="Image Url" fullWidth helperText={false} />
                <RA.TextInput source="media.video" label="Video Url" fullWidth helperText={false} />
            </MUI.Box>

            {
                /**
                 * *******
                 * ANIMATED
                 * *******
                 */
            }
            <RA.FormDataConsumer>
                {({ formData }) => formData?.template === Types.StoryTemplate.animated && (
                    <>
                        <MUI.Typography variant="h6" mt={3}>Animation</MUI.Typography>
                        <RA.TextInput source="animation" label="Lottie JSON" fullWidth helperText={false} />
                    </>
                )
                }
            </RA.FormDataConsumer>

            {
                /**
                 * *******
                 * BULLETED
                 * *******
                 */
            }
            <RA.FormDataConsumer>
                {({ formData }) => formData?.template === Types.StoryTemplate.bulleted && (
                    <>
                        <MUI.Typography variant="h6" mt={3}>Items List</MUI.Typography>
                        <RA.TranslatableInputs
                            locales={locales}
                            defaultLocale={selectedLocale}
                            groupKey="items_list_localization"
                            selector={
                                <TileLocaleSelector onChange={handleLocaleChange} />
                            }
                        >
                            <RA.ArrayInput source="items_list">
                                <RA.SimpleFormIterator inline>
                                    <RA.TextInput source="" label={'item'} helperText={false} />
                                </RA.SimpleFormIterator>
                            </RA.ArrayInput>
                        </RA.TranslatableInputs>
                    </>
                )
                }
            </RA.FormDataConsumer>

            <MUI.Typography variant="h6" mt={3}>Tracking Label</MUI.Typography>
            <RA.TextInput source="tracking_label" label="tracking_label" fullWidth helperText={false} />

        </RA.SimpleForm>
    </MUI.Card>;
};

export default StoryScreenForm;
