import React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';

import { useFormContext } from 'react-hook-form';

import { locales } from '../../../locales';
import { LinkType } from '../../../types';
import { TileLocaleSelector } from '../../FormComponents/TileLocaleSelector';
// import { TitleValidation } from '../../../formValidations/validateTitle';
import { urlRegex } from '../../../constants';

import useMultipleModal from '../../Modal/useMultipleModal';
import StoryScreenForm from '../StoryScreens/StoryScreenForm';
import StoryScreenEditModal from '../StoryScreens/StoryScreenEditModal';
import StoryScreenEditAction from '../StoryScreens/StoryScreenEditAction';
import StoryScreenCreateModal from '../StoryScreens/StoryScreenCreateModal';
import StoryScreenCreateAction from '../StoryScreens/StoryScreenCreateAction';
import StoryScreenDeleteAction from '../StoryScreens/StoryScreenDeleteAction';
import { TitleValidation } from '../../../formValidations/validateTitle';

const colorRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

const linkTypes = [
    { id: LinkType.browser, name: 'BROWSER' },
    { id: LinkType.deeplink, name: 'DEEPLINK' },
    { id: LinkType.webview, name: 'WEBVIEW' },
];

const ServiceContentPanel = () => {
    const { getValues } = useFormContext();

    const { selectLocale, selectedLocale } = RA.useTranslatableContext();
    const handleLocaleChange = (locale: string) => selectLocale(locale);

    const { handleOpenId, handleClose, open } = useMultipleModal();

    const sort = { field: 'id', order: 'ASC' };
    const storyScreenData = getValues('content.story_screens');

    React.useEffect(() => {}, [storyScreenData]);

    return (
        <MUI.Box>
            {/* Text */}
            <MUI.Typography variant="h6" mt={3}>Text Localization</MUI.Typography>
            <RA.TranslatableInputs
                locales={locales}
                defaultLocale={selectedLocale}
                groupKey="text-localization"
                selector={
                    <TileLocaleSelector onChange={handleLocaleChange} />
                }
            >
                <RA.TextInput
                    source="content.title"
                    label="Title"
                    validate={TitleValidation}
                    helperText={false}
                    fullWidth
                />
                <RA.TextInput source="content.incentive" label="Incentive" fullWidth helperText={false} />
                <RA.TextInput source="content.extended_text" label="Extended text" fullWidth helperText={false} />
            </RA.TranslatableInputs>

            {/* Images */}
            <MUI.Typography variant="h6" mt={3}>Icon</MUI.Typography>
            <MUI.Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <RA.TextInput
                    source="content.icon.image"
                    label="Icon image url"
                    validate={[
                        RA.required(),
                        RA.regex(
                            urlRegex,
                            'Picture must be a valid url',
                        ),
                    ]}
                    helperText={false}
                />
                <RA.TextInput
                    source="content.icon.background_color"
                    label="Icon background color"
                    validate={[
                        RA.regex(
                            colorRegex,
                            'Color must be an hexadecimal value (ex: #0000FF)',
                        ),
                    ]}
                    helperText={false}
                />
            </MUI.Box>

            {/* Links */}
            <MUI.Typography variant="h6" mt={3}>Link</MUI.Typography>
            <MUI.Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <RA.TextInput
                    source="content.link"
                    label="Link"
                    validate={[
                        RA.required(),
                        RA.regex(
                            urlRegex,
                            'Link must be a valid url',
                        ),
                    ]}
                    helperText={'To vary the language, country and platform insert {lang}, {country} and/or {platform}'}
                    fullWidth
                />
                <RA.SelectInput
                    source="content.link_type"
                    label="Link type"
                    choices={linkTypes}
                    validate={RA.required()}
                />
            </MUI.Box>

            {/* Story */}
            <MUI.Typography variant="h6" mt={3}>Story</MUI.Typography>

            {storyScreenData && storyScreenData.length >= 1
            && <RA.Datagrid data={storyScreenData} sort={sort}>
                <RA.TextField source="screen_name" label="Screen Name" />
                <RA.TextField source="template" label="Template" />
                <StoryScreenEditAction handleOpenId={handleOpenId} />
                <StoryScreenEditModal
                    open={open}
                    handleClose={handleClose}
                >
                    <StoryScreenForm
                        open={open}
                        handleClose={handleClose}
                    />
                </StoryScreenEditModal>
                <StoryScreenDeleteAction />
            </RA.Datagrid>
            }

            <StoryScreenCreateAction handleOpenId={handleOpenId}/>
            <StoryScreenCreateModal
                open={open}
                handleClose={handleClose}
            >
                <StoryScreenForm
                    open={open}
                    handleClose={handleClose}
                />
            </StoryScreenCreateModal>

        </MUI.Box>
    );
};

export default ServiceContentPanel;
