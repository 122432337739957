import React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';
import { locales } from '../../locales';

// components
import { TilesPreview } from './TilesPreview';
import { TileFormTabs } from './TilesFormTabs';
import { TilesFormHeader } from './TilesFormHeader';

export const InnerTilesForm = ({ toolbar: Toolbar }: any): React.ReactElement => {
    const { selectedLocale } = RA.useTranslatableContext();

    return (
        <MUI.Box flexGrow={1}>
            <RA.SimpleForm
                toolbar={Toolbar}
                mode={'onBlur'}
                warnWhenUnsavedChanges
            >
                <MUI.Box sx={{
                    width: '100%', display: 'flex', justifyContent: 'space-between', gap: 4,
                }}>
                    <MUI.Box width="100%">
                        {/* Tiles form header */}
                        <TilesFormHeader />

                        {/* Content and Settings forms in differents tabs */}
                        <TileFormTabs />

                    </MUI.Box>

                    {/* preview */}
                    <MUI.Box>
                        <RA.FormDataConsumer>
                            {({ formData }) => (
                                <TilesPreview
                                    data={{
                                        picture: formData?.content?.picture,
                                        title: formData?.content?.title,
                                        subtitle: formData?.content?.subtitle,
                                        incentive: formData?.content?.incentive,
                                        picto: formData?.content?.picto,
                                    }}
                                    selectedLocale={selectedLocale}
                                />
                            )}
                        </RA.FormDataConsumer>
                    </MUI.Box>
                </MUI.Box>
            </RA.SimpleForm>
        </MUI.Box>
    );
};

export const TilesForm = (props: any): React.ReactElement => {
    const context = RA.useTranslatable({ locales });

    return (
        <RA.TranslatableContextProvider value={context}>
            <InnerTilesForm {...props} />
        </RA.TranslatableContextProvider>
    );
};
