import * as RA from 'react-admin';
import * as MUI from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { FileCopy } from '@mui/icons-material';

export const CustomBulkCopyIds = () => {
    const { selectedIds } = RA.useListContext();
    const onClick = async () => {
        // eslint-disable-next-line no-undef
        await navigator.clipboard.writeText(selectedIds.toString());
        // eslint-disable-next-line no-alert, no-undef
        alert(`IDs copied to the clipboard : [${selectedIds.toString()}]`);
    };

    return <MUI.Button
        color="primary"
        variant="text"
        onClick={onClick}
        startIcon={<FileCopy />}
    >
        Copy IDs
    </MUI.Button>;
};
