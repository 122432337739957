import React from 'react';
import * as RA from 'react-admin';
import { Route, BrowserRouter as Router } from 'react-router-dom';

// login
import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';
import Login from './providers/authProvider/Login';

// conf
import config, { loadConfig, Config } from './config';
import configureAWSAuthentication from './providers/authProvider/authentication';

import { lightTheme } from './themes/themes';

// components
import CustomLayout from './layout/CustomLayout';
import * as Tiles from './components/Tiles/Tiles';
import * as Components from './components/Components/Components';
import * as Users from './components/Users/Users';
import * as Configs from './components/Configs/Configs';
import { TilesPreviewPage } from './components/Preview/TilesPreviewPage';

const App = () => {
    const [initialized, setInitialized] = React.useState(false);
    const store = RA.localStorageStore();
    store.setItem('sidebar.open', true);

    React.useEffect(() => {
        // Wait for async configuration to be fully loaded
        loadConfig()
            .then((conf: Config) => {
                // eslint-disable-next-line no-console
                console.log('Remote configuration loaded');
                configureAWSAuthentication(conf);
                setInitialized(true);
            })
            .catch((error: Error) => {
                // eslint-disable-next-line no-console
                console.log('An error occured while fetching config', error.message);
                configureAWSAuthentication(config());
                setInitialized(true);
            });
    }, []);

    if (!initialized) return <RA.Loading />;

    const { VITE_API_URL: apiUrl } = config();

    return (
        <Router>
            <RA.Admin
                layout={CustomLayout}
                authProvider={authProvider}
                dataProvider={dataProvider(apiUrl)}
                loginPage={Login}
                theme={lightTheme}
                store={RA.memoryStore()}
                requireAuth
            >
                <RA.Resource
                    name="tiles"
                    options={{ label: 'Tiles' }}
                    list={Tiles.List}
                    edit={Tiles.Edit}
                    create={Tiles.Create}
                />
                <RA.Resource
                    name="components"
                    options={{ label: 'Components' }}
                    list={Components.List}
                    edit={Components.Edit}
                // create={Components.Create}
                />
                <RA.Resource
                    name="app_config_users"
                    options={{ label: 'Users' }}
                    list={Users.List}
                    edit={Users.Edit}
                    create={Users.Create}
                />
                <RA.Resource
                    name="app_config_overrides"
                    options={{ label: 'Configs' }}
                    list={Configs.List}
                    edit={Configs.Edit}
                    create={Configs.Create}
                />
                <RA.CustomRoutes>
                    <Route path="/preview" element={<TilesPreviewPage />} />
                </RA.CustomRoutes>
            </RA.Admin>
        </Router>
    );
};

export default App;
