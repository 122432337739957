export enum StoryTemplate {
    fullscreen = 'fullscreen',
    animated = 'animated',
    bulleted = 'bulleted',
}

export enum LinkType {
    browser = 'BROWSER',
    deeplink = 'DEEPLINK',
    webview = 'WEBVIEW',
}

export enum RuleType {
    include = 'include',
    exclude = 'exclude',
    none = 'none',
}

export enum PlatformType {
    ios = 'ios',
    android = 'android',
    any = 'any',
}

export enum UserType {
    everyone = 'everyone',
    accorPlus = 'accorPlus',
    memberAllNoAccorPlus='memberAllNoAccorPlus',
    everyMemberAll='everyMemberAll',
    prospect = 'prospect',
}

export enum Tiering {
    classic = 'classic',
    silver = 'silver',
    gold = 'gold',
    platinum = 'platinum',
    diamond = 'diamond',
    limitless = 'limitless',
}

export enum ExpirationTime {
    anytime = 'anytime',
    oneMonth = 'oneMonth',
    twoMonths = 'twoMonths',
    threeMonths = 'threeMonths',
}

export enum Target {
    carousel = 'carousel',
    appHome = 'appHome',
    any = 'any',
}

export enum CobrandVisas {
    CGPREC = 'CGPREC',
    CGMCB2 = 'CGMCB2',
    CGMCB1 = 'CGMCB1',
    CGECB2 = 'CGECB2',
    CGECB1 = 'CGECB1',
    CFPREC = 'CFPREC',
    CFMCB2 = 'CFMCB2',
    CFMCB1 = 'CFMCB1',
    CFECB2 = 'CFECB2',
    CFECB1 = 'CFECB1',
    ADCBSC = 'ADCBSC',
    ADCBIC = 'ADCBIC',
}

export enum CobrandFlyingPartner {
    AirFrance = 'AF',
    Qantas = 'QF',
    Qatar = 'QR',
    NoSubscription= 'NA'
}

export enum CobrandAllSignature {
    SIGPL1 = 'SIGPL1',
    SIGPL2 = 'SIGPL2',
    SIGPL3 = 'SIGPL3',
    NoSubscription = 'NA',
}

// enum for All Plus card
export enum AllPlusCard {
    FI = 'FI',
    B3 = 'B3',
    NoSubscription = 'NA',
}

// enum for Woori Card
export enum WooriCard {
    WOOID1 = 'WOOID1',
    WOOID2 = 'WOOID2',
}

// export type Tile = {
//     priority: number;
//     title: { [key: string]: string };
//     subtitle: { [key: string]: string };
//     incentive: { [key: string]: string };
//     extended_text: { [key: string]: string } | null;
//     link: string;
//     link_type: LinkType;
//     picture: string | null;
//     picto: string | null;
//     tracking_label: string | null;
//     countries: string[] | null;
//     countries_rule: RuleType;
//     languages: string[] | null;
//     languages_rule: RuleType;
//     platforms: PlatformType;
//     user_type: UserType;
//     tiering: Array<Tiering> | null;
//     user_card_expiration: ExpirationTime;
//     cobrand_flying_partner: CobrandFlyingPartner[] | null;
//     cobrand_all_signature: CobrandAllSignature[] | null;
//     cobrand_visa: CobrandVisas[] | null;
//     filter_by_cobrand_visa: boolean;
//     filter_by_cobrand_flying_partner: boolean;
//     filter_by_cobrand_flying_partner_inclusion: string;
//     filter_by_cobrand_all_signature: boolean;
//     filter_by_cobrand_all_signature_inclusion: string;
//     reward_points_minimum_enabled: boolean;
//     reward_points_minimum: number;
//     reward_points_maximum_enabled: boolean;
//     reward_points_maximum: number;
// };

export type TileProps = {
    tile: {
        picture?: string;
        title?: string;
        subtitle?: string;
        incentive?: string;
        picto?: string;
    };
};

export type Media = {
    image: string;
    video: string;
};

export type StoryTemplateCore = {
    id?: number | string;
    template: string;
    supertitle?: Record<string, string>;
    title: Record<string, string>;
    incentive?: Record<string, string>;
    body?: Record<string, string>;
    body_extended?: Record<string, string>;
    media?: Media;
    tracking_label?: string;
};

export type StoryTemplateFullscreen = StoryTemplateCore;

export type StoryTemplateAnimated = StoryTemplateCore & {
    animation?: string;
};

export type StoryTemplateBulleted = StoryTemplateCore & {
    items_list?: Record<string, string[]>;
};

export type StoryScreen = StoryTemplateFullscreen | StoryTemplateAnimated | StoryTemplateBulleted;

export type StandardTileContent = {
    title: Record<string, string>;
    subtitle: Record<string, string>;
    incentive: Record<string, string>;
    extended_text: Record<string, string>;
    link?: string;
    link_type?: string;
    picture?: string;
    picto?: string;
    tracking_label?: string;
};

export type ServiceTileContentIcon = {
    image: string;
    background_color: string;
};

export type ServiceTileContent = {
    title: Record<string, string>;
    incentive?: Record<string, string>;
    extended_text?: Record<string, string>;
    link: string;
    link_type: string;
    icon: ServiceTileContentIcon;
    tracking_label?: string;
    story_screens: StoryScreen[];
};

export type TileContent = StandardTileContent | ServiceTileContent;

export type Tile = {
    _id?: number;
    id: number;
    priority: number;
    tracking_label?: string;
    platforms: string;
    countries_rule: RuleType;
    countries: string;
    languages: string[] | null;
    languages_rule: RuleType;
    tiering: string;
    cobrand_visa: string;
    cobrand_flying_partner: string | null;
    cobrand_all_signature: string | null;
    all_plus_card: string | null;
    woori_card?: WooriCard[];

    all_signature_rule?: string;
    all_plus_card_rule?: string;
    flying_partner_rule?: string;
    filter_by_cobrand_visa: boolean;
    filter_by_cobrand_flying_partner: boolean;
    filter_by_cobrand_all_signature: boolean;
    filter_by_all_plus_card: boolean;
    filter_by_woori_card: boolean;
    user_type: string;
    user_card_expiration: string;
    reward_points_minimum_enabled?: boolean;
    reward_points_maximum_enabled?: boolean;
    reward_points_minimum?: number;
    reward_points_maximum?: number;
    published: boolean;
    tilename: string;
    type: string;
    content: TileContent;
    target: string;
};

export interface TileValidation {
    content: {
        picture: string;
        title: Record<string, string>;
        subtitle: Record<string, string>;
        incentive?: Record<string, string>;
        extended_text?: Record<string, string>;
        picto?: string;
        icon?: {
            image: string;
            background_color: string;
        };
        link: string;
        linkType: LinkType;
        tracking_label?: string;
        story_screens: {
            id: number | string;
            screen_name: string;
            template: string;
            title?: Record<string, string>;
            supertitle?: Record<string, string>;
            body?: Record<string, string>;
            body_extended?: Record<string, string>;
            media?: {
                image: string;
                video: string;
            };
            animation?: string;
            items_list?: Record<string, string[]>;
            tracking_label?: string;
        };
    };
    priority: number;
    countries: string[] | null;
    countries_rule: RuleType;
    languages: string[] | null;
    languages_rule: RuleType;
    platforms: PlatformType;
    user_type: UserType;
    tiering?: Array<Tiering>;
    user_card_expiration: ExpirationTime;
    cobrand_flying_partner?: CobrandFlyingPartner[];
    cobrand_all_signature?: CobrandAllSignature[];
    cobrand_visa?: CobrandVisas[];
    all_plus_card?: AllPlusCard[];
    woori_card?: WooriCard[];
    filter_by_cobrand_visa: boolean;
    filter_by_cobrand_flying_partner: boolean;
    filter_by_cobrand_all_signature: boolean;
    filter_by_all_plus_card: boolean;
    filter_by_woori_card: boolean;
    reward_points_minimum_enabled: boolean;
    reward_points_minimum: number;
    reward_points_maximum_enabled: boolean;
    reward_points_maximum: number;
}
