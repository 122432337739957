export const locales = [
    'en',
    'ar',
    'de',
    'es',
    'fr',
    'id',
    'it',
    'ja',
    'ko',
    'nl',
    'pl',
    'pt',
    'pt-BR',
    'ru',
    'th',
    'tr',
    'vi',
    'zh',
];

export const localesChoices = [
    { name: 'en', id: 'en' },
    { name: 'ar', id: 'ar' },
    { name: 'de', id: 'de' },
    { name: 'fr', id: 'fr' },
    { name: 'id', id: 'id' },
    { name: 'it', id: 'it' },
    { name: 'ja', id: 'ja' },
    { name: 'ko', id: 'ko' },
    { name: 'nl', id: 'nl' },
    { name: 'pl', id: 'pl' },
    { name: 'pt', id: 'pt' },
    { name: 'pt-BR', id: 'pt-BR' },
    { name: 'ru', id: 'ru' },
    { name: 'th', id: 'th' },
    { name: 'tr', id: 'tr' },
    { name: 'vi', id: 'vi' },
    { name: 'zh', id: 'zh' },
];

export const countryListAllIsoData = [
    { id: 'af', name: 'Afghanistan' },
    { id: 'al', name: 'Albania' },
    { id: 'dz', name: 'Algeria' },
    { id: 'as', name: 'American Samoa' },
    { id: 'ad', name: 'Andorra' },
    { id: 'ao', name: 'Angola' },
    { id: 'ai', name: 'Anguilla' },
    { id: 'aq', name: 'Antarctica' },
    { id: 'ag', name: 'Antigua and Barbuda' },
    { id: 'ar', name: 'Argentina' },
    { id: 'am', name: 'Armenia' },
    { id: 'aw', name: 'Aruba' },
    { id: 'au', name: 'Australia' },
    { id: 'at', name: 'Austria' },
    { id: 'az', name: 'Azerbaijan' },
    { id: 'bs', name: 'Bahamas (the)' },
    { id: 'bh', name: 'Bahrain' },
    { id: 'bd', name: 'Bangladesh' },
    { id: 'bb', name: 'Barbados' },
    { id: 'by', name: 'Belarus' },
    { id: 'be', name: 'Belgium' },
    { id: 'bz', name: 'Belize' },
    { id: 'bj', name: 'Benin' },
    { id: 'bm', name: 'Bermuda' },
    { id: 'bt', name: 'Bhutan' },
    { id: 'bo', name: 'Bolivia (Plurinational State of)' },
    { id: 'bq', name: 'Bonaire, Sint Eustatius and Saba' },
    { id: 'ba', name: 'Bosnia and Herzegovina' },
    { id: 'bw', name: 'Botswana' },
    { id: 'bv', name: 'Bouvet Island' },
    { id: 'br', name: 'Brazil' },
    { id: 'io', name: 'British Indian Ocean Territory (the)' },
    { id: 'bn', name: 'Brunei Darussalam' },
    { id: 'bg', name: 'Bulgaria' },
    { id: 'bf', name: 'Burkina Faso' },
    { id: 'bi', name: 'Burundi' },
    { id: 'cv', name: 'Cabo Verde' },
    { id: 'kh', name: 'Cambodia' },
    { id: 'cm', name: 'Cameroon' },
    { id: 'ca', name: 'Canada' },
    { id: 'ky', name: 'Cayman Islands (the)' },
    { id: 'cf', name: 'Central African Republic (the)' },
    { id: 'td', name: 'Chad' },
    { id: 'cl', name: 'Chile' },
    { id: 'cn', name: 'China' },
    { id: 'cx', name: 'Christmas Island' },
    { id: 'cc', name: 'Cocos (Keeling) Islands (the)' },
    { id: 'co', name: 'Colombia' },
    { id: 'km', name: 'Comoros (the)' },
    { id: 'cd', name: 'Congo (the Democratic Republic of the)' },
    { id: 'cg', name: 'Congo (the)' },
    { id: 'ck', name: 'Cook Islands (the)' },
    { id: 'cr', name: 'Costa Rica' },
    { id: 'hr', name: 'Croatia' },
    { id: 'cu', name: 'Cuba' },
    { id: 'cw', name: 'Curaçao' },
    { id: 'cy', name: 'Cyprus' },
    { id: 'cz', name: 'Czechia' },
    { id: 'ci', name: "Côte d'Ivoire" },
    { id: 'dk', name: 'Denmark' },
    { id: 'dj', name: 'Djibouti' },
    { id: 'dm', name: 'Dominica' },
    { id: 'do', name: 'Dominican Republic (the)' },
    { id: 'ec', name: 'Ecuador' },
    { id: 'eg', name: 'Egypt' },
    { id: 'sv', name: 'El Salvador' },
    { id: 'gq', name: 'Equatorial Guinea' },
    { id: 'er', name: 'Eritrea' },
    { id: 'ee', name: 'Estonia' },
    { id: 'sz', name: 'Eswatini' },
    { id: 'et', name: 'Ethiopia' },
    { id: 'fk', name: 'Falkland Islands (the) [Malvinas]' },
    { id: 'fo', name: 'Faroe Islands (the)' },
    { id: 'fj', name: 'Fiji' },
    { id: 'fi', name: 'Finland' },
    { id: 'fr', name: 'France' },
    { id: 'gf', name: 'French Guiana' },
    { id: 'pf', name: 'French Polynesia' },
    { id: 'tf', name: 'French Southern Territories (the)' },
    { id: 'ga', name: 'Gabon' },
    { id: 'gm', name: 'Gambia (the)' },
    { id: 'ge', name: 'Georgia' },
    { id: 'de', name: 'Germany' },
    { id: 'gh', name: 'Ghana' },
    { id: 'gi', name: 'Gibraltar' },
    { id: 'gr', name: 'Greece' },
    { id: 'gl', name: 'Greenland' },
    { id: 'gd', name: 'Grenada' },
    { id: 'gp', name: 'Guadeloupe' },
    { id: 'gu', name: 'Guam' },
    { id: 'gt', name: 'Guatemala' },
    { id: 'gg', name: 'Guernsey' },
    { id: 'gn', name: 'Guinea' },
    { id: 'gw', name: 'Guinea-Bissau' },
    { id: 'gy', name: 'Guyana' },
    { id: 'ht', name: 'Haiti' },
    { id: 'hm', name: 'Heard Island and McDonald Islands' },
    { id: 'va', name: 'Holy See (the)' },
    { id: 'hn', name: 'Honduras' },
    { id: 'hk', name: 'Hong Kong' },
    { id: 'hu', name: 'Hungary' },
    { id: 'is', name: 'Iceland' },
    { id: 'in', name: 'India' },
    { id: 'id', name: 'Indonesia' },
    { id: 'ir', name: 'Iran (Islamic Republic of)' },
    { id: 'iq', name: 'Iraq' },
    { id: 'ie', name: 'Ireland' },
    { id: 'im', name: 'Isle of Man' },
    { id: 'il', name: 'Israel' },
    { id: 'it', name: 'Italy' },
    { id: 'jm', name: 'Jamaica' },
    { id: 'jp', name: 'Japan' },
    { id: 'je', name: 'Jersey' },
    { id: 'jo', name: 'Jordan' },
    { id: 'kz', name: 'Kazakhstan' },
    { id: 'ke', name: 'Kenya' },
    { id: 'ki', name: 'Kiribati' },
    { id: 'kp', name: "Korea (the Democratic People's Republic of)" },
    { id: 'kr', name: 'Korea (the Republic of)' },
    { id: 'kw', name: 'Kuwait' },
    { id: 'kg', name: 'Kyrgyzstan' },
    { id: 'la', name: "Lao People's Democratic Republic (the)" },
    { id: 'lv', name: 'Latvia' },
    { id: 'lb', name: 'Lebanon' },
    { id: 'ls', name: 'Lesotho' },
    { id: 'lr', name: 'Liberia' },
    { id: 'ly', name: 'Libya' },
    { id: 'li', name: 'Liechtenstein' },
    { id: 'lt', name: 'Lithuania' },
    { id: 'lu', name: 'Luxembourg' },
    { id: 'mo', name: 'Macao' },
    { id: 'mg', name: 'Madagascar' },
    { id: 'mw', name: 'Malawi' },
    { id: 'my', name: 'Malaysia' },
    { id: 'mv', name: 'Maldives' },
    { id: 'ml', name: 'Mali' },
    { id: 'mt', name: 'Malta' },
    { id: 'mh', name: 'Marshall Islands (the)' },
    { id: 'mq', name: 'Martinique' },
    { id: 'mr', name: 'Mauritania' },
    { id: 'mu', name: 'Mauritius' },
    { id: 'yt', name: 'Mayotte' },
    { id: 'mx', name: 'Mexico' },
    { id: 'fm', name: 'Micronesia (Federated States of)' },
    { id: 'md', name: 'Moldova (the Republic of)' },
    { id: 'mc', name: 'Monaco' },
    { id: 'mn', name: 'Mongolia' },
    { id: 'me', name: 'Montenegro' },
    { id: 'ms', name: 'Montserrat' },
    { id: 'ma', name: 'Morocco' },
    { id: 'mz', name: 'Mozambique' },
    { id: 'mm', name: 'Myanmar' },
    { id: 'na', name: 'Namibia' },
    { id: 'nr', name: 'Nauru' },
    { id: 'np', name: 'Nepal' },
    { id: 'nl', name: 'Netherlands (the)' },
    { id: 'nc', name: 'New Caledonia' },
    { id: 'nz', name: 'New Zealand' },
    { id: 'ni', name: 'Nicaragua' },
    { id: 'ne', name: 'Niger (the)' },
    { id: 'ng', name: 'Nigeria' },
    { id: 'nu', name: 'Niue' },
    { id: 'nf', name: 'Norfolk Island' },
    { id: 'mp', name: 'Northern Mariana Islands (the)' },
    { id: 'no', name: 'Norway' },
    { id: 'om', name: 'Oman' },
    { id: 'pk', name: 'Pakistan' },
    { id: 'pw', name: 'Palau' },
    { id: 'ps', name: 'Palestine, State of' },
    { id: 'pa', name: 'Panama' },
    { id: 'pg', name: 'Papua New Guinea' },
    { id: 'py', name: 'Paraguay' },
    { id: 'pe', name: 'Peru' },
    { id: 'ph', name: 'Philippines (the)' },
    { id: 'pn', name: 'Pitcairn' },
    { id: 'pl', name: 'Poland' },
    { id: 'pt', name: 'Portugal' },
    { id: 'pr', name: 'Puerto Rico' },
    { id: 'qa', name: 'Qatar' },
    { id: 'mk', name: 'Republic of North Macedonia' },
    { id: 'ro', name: 'Romania' },
    { id: 'ru', name: 'Russian Federation (the)' },
    { id: 'rw', name: 'Rwanda' },
    { id: 're', name: 'Réunion' },
    { id: 'bl', name: 'Saint Barthélemy' },
    { id: 'sh', name: 'Saint Helena, Ascension and Tristan da Cunha' },
    { id: 'kn', name: 'Saint Kitts and Nevis' },
    { id: 'lc', name: 'Saint Lucia' },
    { id: 'mf', name: 'Saint Martin (French part)' },
    { id: 'pm', name: 'Saint Pierre and Miquelon' },
    { id: 'vc', name: 'Saint Vincent and the Grenadines' },
    { id: 'ws', name: 'Samoa' },
    { id: 'sm', name: 'San Marino' },
    { id: 'st', name: 'Sao Tome and Principe' },
    { id: 'sa', name: 'Saudi Arabia' },
    { id: 'sn', name: 'Senegal' },
    { id: 'rs', name: 'Serbia' },
    { id: 'sc', name: 'Seychelles' },
    { id: 'sl', name: 'Sierra Leone' },
    { id: 'sg', name: 'Singapore' },
    { id: 'sx', name: 'Sint Maarten (Dutch part)' },
    { id: 'sk', name: 'Slovakia' },
    { id: 'si', name: 'Slovenia' },
    { id: 'sb', name: 'Solomon Islands' },
    { id: 'so', name: 'Somalia' },
    { id: 'za', name: 'South Africa' },
    { id: 'gs', name: 'South Georgia and the South Sandwich Islands' },
    { id: 'ss', name: 'South Sudan' },
    { id: 'es', name: 'Spain' },
    { id: 'lk', name: 'Sri Lanka' },
    { id: 'sd', name: 'Sudan (the)' },
    { id: 'sr', name: 'Suriname' },
    { id: 'sj', name: 'Svalbard and Jan Mayen' },
    { id: 'se', name: 'Sweden' },
    { id: 'ch', name: 'Switzerland' },
    { id: 'sy', name: 'Syrian Arab Republic' },
    { id: 'tw', name: 'Taiwan' },
    { id: 'tj', name: 'Tajikistan' },
    { id: 'tz', name: 'Tanzania, United Republic of' },
    { id: 'th', name: 'Thailand' },
    { id: 'tl', name: 'Timor-Leste' },
    { id: 'tg', name: 'Togo' },
    { id: 'tk', name: 'Tokelau' },
    { id: 'to', name: 'Tonga' },
    { id: 'tt', name: 'Trinidad and Tobago' },
    { id: 'tn', name: 'Tunisia' },
    { id: 'tr', name: 'Turkey' },
    { id: 'tm', name: 'Turkmenistan' },
    { id: 'tc', name: 'Turks and Caicos Islands (the)' },
    { id: 'tv', name: 'Tuvalu' },
    { id: 'ug', name: 'Uganda' },
    { id: 'ua', name: 'Ukraine' },
    { id: 'ae', name: 'United Arab Emirates (the)' },
    {
        id: 'gb',
        name: 'United Kingdom of Great Britain and Northern Ireland (the)',
    },
    { id: 'um', name: 'United States Minor Outlying Islands (the)' },
    { id: 'us', name: 'United States of America (the)' },
    { id: 'uy', name: 'Uruguay' },
    { id: 'uz', name: 'Uzbekistan' },
    { id: 'vu', name: 'Vanuatu' },
    { id: 've', name: 'Venezuela (Bolivarian Republic of)' },
    { id: 'vn', name: 'Viet Nam' },
    { id: 'vg', name: 'Virgin Islands (British)' },
    { id: 'vi', name: 'Virgin Islands (U.S.)' },
    { id: 'wf', name: 'Wallis and Futuna' },
    { id: 'eh', name: 'Western Sahara' },
    { id: 'ye', name: 'Yemen' },
    { id: 'zm', name: 'Zambia' },
    { id: 'zw', name: 'Zimbabwe' },
    { id: 'ax', name: 'Åland Islands' },
];
