import * as React from 'react';
import * as MUI from '@mui/material';
import { Tile } from '../Tile';
import { Tile as TileType } from '../../types';

type TilesPreviewProps = any & {
    selectedLocale: string;
    data: TileType[];
};

export const TilesPreviewItems = ({ selectedLocale, data }: TilesPreviewProps): React.ReactElement | null => {
    const [tiles, setTiles] = React.useState<TileType[] | null>(null);

    React.useEffect(() => {
        if (data) setTiles(data);
    }, [data]);

    if (!tiles) return null;

    return (
        <MUI.Box display="flex" flexWrap={'wrap'} gap={2} >
            {tiles.map((tile: any, index: number) => (
                <MUI.Box key={index}>
                    <Tile tile={{
                        picture: tile?.content?.picture,
                        title: tile?.content?.title?.[selectedLocale],
                        subtitle: tile?.content?.subtitle?.[selectedLocale],
                        incentive: tile?.content?.incentive?.[selectedLocale],
                        picto: tile?.content?.picto,
                    }} />
                </MUI.Box>
            ))}
        </MUI.Box>
    );
};
