import * as React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Delete } from '@mui/icons-material';

export const CustomBulkDelete = ({ resource }): React.ReactElement => {
    const { selectedIds } = RA.useListContext();
    const [open, setOpen] = React.useState(false);

    const refresh = RA.useRefresh();
    const notify = RA.useNotify();
    const unselectAll = RA.useUnselectAll(resource);

    const [deleteMany, { isLoading }] = RA.useDeleteMany(
        resource,
        { ids: selectedIds },
        {
            onSuccess: () => {
                refresh();
                notify(`${resource} successfully deleted`);
                unselectAll();
            },
            onError: () => notify(`Error: ${resource} not deleted`, { type: 'warning' }),
        },

    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        deleteMany();
        setOpen(false);
    };

    return <>
        <MUI.Button
            color="error"
            variant="text"
            onClick={handleClick}
            startIcon={<Delete />}
        >
            Delete
        </MUI.Button>
        <RA.Confirm
            isOpen={open}
            loading={isLoading}
            title={`Delete ${resource}`}
            content="Are you sure you want to delete these items?"
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
        />
    </>;
};
