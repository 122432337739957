import React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';
import * as Types from '../../types';

export const TilesFilters: React.FC<any> = ({ onChange, filters }) => (
    <RA.Form
        onSubmit={onChange}
        defaultValues={filters}
    >
        <MUI.Box display={'flex'} alignItems={'center'} gap={1} sx={{
            '& .MuiFormControl-root': {
                margin: 0,
                flex: 1,
                maxWidth: 200,
            },
        }}>
            <RA.SelectInput
                source="type"
                defaultValue={'standard'}
                choices={[
                    { id: 'standard', name: 'Standard Tile' },
                    { id: 'service', name: 'Service Tile' },
                ]}
                onChange={(e: any) => onChange({
                    ...filters,
                    type: `${e.target.value}`,
                })}
                validate={RA.required()}
                helperText={false}
            />
            <RA.SelectInput
                source="platforms"
                defaultValue={Types.PlatformType.any}
                choices={[
                    { id: Types.PlatformType.ios, name: 'IOS' },
                    { id: Types.PlatformType.android, name: 'Android' },
                    { id: Types.PlatformType.any, name: 'IOS & Android' },
                ]}
                onChange={(e: any) => onChange({
                    ...filters,
                    platforms: `${e.target.value}`,
                })}
                validate={RA.required()}
                helperText={false}
            />

            <RA.SelectInput
                source="target"
                defaultValue={Types.Target.any}
                choices={[
                    { id: Types.Target.carousel, name: 'Carousel' },
                    { id: Types.Target.appHome, name: 'AppHome' },
                    { id: Types.Target.any, name: 'Carousel & appHome' },
                ]}
                onChange={(e: any) => onChange({
                    ...filters,
                    target: `${e.target.value}`,
                })}
                validate={RA.required()}
                helperText={false}
            />

            <RA.SelectInput
                source="user_type"
                choices={[
                    { id: Types.UserType.everyone, name: 'Everyone' },
                    {
                        id: Types.UserType.accorPlus,
                        name: 'Accor Plus (D1, D2, ...)',
                    },
                    {
                        id: Types.UserType.memberAllNoAccorPlus,
                        name: 'Membres ALL sans les Accor Plus (A1, A2, ...)',
                    },
                    {
                        id: Types.UserType.everyMemberAll,
                        name: 'Membres ALL (A* & D* & P*)',
                    },
                    {
                        id: Types.UserType.prospect,
                        name: 'Prospect (pas de carte ou pas connecté)',
                    },
                ]}
                validate={RA.required()}
                onChange={(e: any) => onChange({
                    ...filters,
                    user_type: `${e.target.value}`,
                })}
                helperText={false}
                fullWidth
            />

        </MUI.Box>
    </RA.Form >
);
