import * as RA from 'react-admin';
import * as MUI from '@mui/material';

// eslint-disable-next-line import/no-extraneous-dependencies
import EditIcon from '@mui/icons-material/Edit';

const StoryScreenEditAction = ({ handleOpenId }) => {
    const record = RA.useRecordContext();

    return (
        <MUI.Button
            variant="text"
            color="primary"
            onClick={handleOpenId(record.id)}
            startIcon={<EditIcon />}
        >
            Edit
        </MUI.Button>
    );
};

export default StoryScreenEditAction;
