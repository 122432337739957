// eslint-disable-next-line import/no-extraneous-dependencies
import { AuthProvider } from 'ra-core';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

const authProvider: AuthProvider = {
    checkAuth: async () => {
        try {
            await Auth.currentSession();
        } catch (e) {
            // Clean all localStorage keys relative to cognito connexion
            Object.keys(localStorage)
                .filter(
                    (key) => key.startsWith('CognitoIdentityServiceProvider')
                        || key.startsWith('amplify-'),
                )
                .forEach((key) => localStorage.removeItem(key));

            throw e;
        }
    },
    checkError: () => Promise.resolve(),
    getPermissions: () => Promise.resolve({}),
    login: async (params: any) => {
        // eslint-disable-next-line no-underscore-dangle
        if (params._withAzure) {
            return Auth.federatedSignIn({
                provider: CognitoHostedUIIdentityProvider.Cognito,
            });
        }

        const { username, password } = params;
        return Auth.signIn(username, password);
    },
    logout: () => Auth.signOut(),
};

export default authProvider;
