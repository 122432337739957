import * as React from 'react';
import * as RA from 'react-admin';
import * as MUI from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { JsonField } from 'react-admin-json-view';

import CustomEditTitle from '../CustomTitle/CustomEditTitle';

import { BulkActionButtons } from '../CustomButtons/BulkActionButtons';
import { ConfigsForm } from './ConfigsForm';

const ListPagination = (props: RA.PaginationProps) => <RA.Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

const configsListFilters = [
    <RA.SearchInput source={'slug'} placeholder='Slug' alwaysOn />,
];

export const List = (): React.ReactElement => (
    <MUI.Box>
        <MUI.Box flex={1}>
            <RA.List
                perPage={100}
                pagination={<ListPagination />}
                filters={configsListFilters}
                hasShow={false}
            >
                <RA.Datagrid
                    bulkActionButtons={<BulkActionButtons />}
                >
                    <RA.TextField source="slug" label="Slug" />
                    <RA.DateField source="date_start" label="Date Start" showTime={false} emptyText="N/A" />
                    <RA.DateField source="date_end" label="Date End" showTime={false} emptyText="N/A" />
                    <JsonField
                        source="keys"
                        jsonString={false} // Set to true if the value is a string, default: false
                        reactJsonOptions={{
                            // Props passed to react-json-view
                            name: null,
                            collapsed: true,
                            enableClipboard: false,
                            displayDataTypes: false,
                        }}
                    />
                    <RA.EditButton />
                </RA.Datagrid>
            </RA.List>
        </MUI.Box>
    </MUI.Box>
);

const CreateToolBar = (props: RA.ToolbarProps): React.ReactElement => (
    <RA.Toolbar {...props}>
        <RA.SaveButton
            type="button"
            alwaysEnable
        />
    </RA.Toolbar>
);

export const Create = (): React.ReactElement => (
    <RA.Create redirect={'list'}>
        <ConfigsForm toolbar={<CreateToolBar />} />
    </RA.Create>
);

const EditToolBar = (): React.ReactElement => (
    <RA.Toolbar sx={{ gap: 4 }}>
        <RA.SaveButton
            type="button"
            alwaysEnable
        />
        <RA.DeleteWithConfirmButton />
    </RA.Toolbar>
);

export const Edit = (): React.ReactElement => (
    <RA.Edit title={<CustomEditTitle name={'config'} field={'slug'} />}>
        <ConfigsForm toolbar={<EditToolBar />} />
    </RA.Edit>
);
