import * as React from 'react';
import { useResourceDefinitions, Menu } from 'react-admin';
import LabelIcon from '@mui/icons-material/Label';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomMenu: React.FC<any> = ({ onMenuClick, logout }) => {
    const resources = useResourceDefinitions();

    return (
        <Menu>
            {Object.keys(resources).map((name) => (
                <Menu.Item
                    key={name}
                    to={`/${name}`}
                    primaryText={(resources[name].options && resources[name].options.label) || name}
                    leftIcon={<LabelIcon />}
                />
            ))}
            <Menu.Item to="/preview" primaryText="Tiles Preview" leftIcon={<LabelIcon />} />
        </Menu>
    );
};

export default CustomMenu;
