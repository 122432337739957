import { useFormContext } from 'react-hook-form';
import { ChangeEvent } from 'react';

const isUndefined = (value: any): value is undefined => typeof value === 'undefined';
const isPlainObject = (value: any): value is object => typeof value === 'object' && !Array.isArray(value);

export interface LinkedInput {
    targetName: string;
    targetValue?: any;
    targetValueFn?: (sourceValue: any) => any;
}

const checkLinkedInputs = (linkedInputs: LinkedInput[]) => {
    linkedInputs.forEach((linkedInput) => {
        const { targetValue, targetValueFn } = linkedInput;

        if (isUndefined(targetValue) && isUndefined(targetValueFn)) {
            throw new Error('targetValue or targetValueFn required');
        }
    });
};

const useMultipleLinkedInputs = (linkedInputs: LinkedInput[]) => {
    const { setValue } = useFormContext();

    checkLinkedInputs(linkedInputs);

    // eslint-disable-next-line no-undef
    const onChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | any) => {
        linkedInputs.forEach((linkedInput) => {
            const {
                targetName,
                targetValue,
                targetValueFn,
            } = linkedInput;

            const computedValue = isUndefined(targetValueFn)
                ? targetValue
                : targetValueFn(isPlainObject(event?.target) ? event.target.value : event);
            if (!isUndefined(computedValue)) setValue(targetName, computedValue);
        });
    };

    return onChange;
};

export default useMultipleLinkedInputs;
