import React from 'react';
import * as MUI from '@mui/material';

import { TileProps } from '../types';

import roomImage from '../assets/room.png';

export const Tile = (props: TileProps): React.ReactElement => (
    <MUI.Card
        sx={{
            width: 400,
            minHeight: 330,
            padding: 2,
            paddingBottom: 0,
        }}>
        <MUI.CardMedia
            component="img"
            height="200"
            src={props.tile.picture || roomImage}
            sx={{ borderRadius: 2 }}
        />
        <MUI.CardContent sx={{ padding: '16px 0' }}>
            <MUI.Typography
                variant="body1"
                color="textSecondary"
                component="p"
                fontWeight={'bold'}
            >
                {props.tile.subtitle || (
                    <span>
                            ▓▓ ▓▓▓ ▓ ▓▓▓▓▓ ▓▓▓▓▓▓▓▓▓▓ ▓ ▓▓▓▓▓▓▓▓▓▓ ▓▓▓▓▓▓▓▓▓▓▓▓
                    </span>
                )}
            </MUI.Typography>
            <MUI.Typography
                variant="h5"
                color="textPrimary"
                component="p"
                pt={1}
                fontWeight={'bold'}
            >
                {props.tile.title || <span>▓▓▓▓▓ ▓▓▓ ▓▓▓▓▓▓▓▓▓</span>}
            </MUI.Typography>
            <MUI.Typography
                variant="body1"
                component="p"
                color={'#74BBBB'}
                pt={1}
                fontSize={'1.4em'}
            >
                {props.tile.incentive || <span>▓▓ ▓▓▓▓▓▓▓▓▓▓</span>}
            </MUI.Typography>
        </MUI.CardContent>
    </MUI.Card>
);
